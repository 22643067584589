// UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { handleLogout } from "../utils/handleLogout";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ id: null, role: null });
  const [localLoading, setLocalLoading] = useState(true);
  const [drakMode, setDarkMode] = useState(false)

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const userRole = localStorage.getItem("userRole");
    if (userId && userRole) {
      setUser({ id: userId, role: userRole });
    }
    setLocalLoading(false);
  }, []);

  useEffect(() => {
    // Check if the user session is still valid
    const loginTime = localStorage.getItem("loginTime");
    if (loginTime) {
      const currentTime = new Date().getTime();
      const timeElapsed = currentTime - loginTime;

      // If more than 1 minute has passed, log out the user
      if (timeElapsed > 24 * 60 * 60 * 1000) {
        handleLogout();
      } else {
        // Schedule logout for the remaining time
        setTimeout(() => {
          handleLogout();
        }, 24 * 60 * 60 * 1000 - timeElapsed);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, localLoading, drakMode, setDarkMode }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
