import React, { useEffect, useMemo, useState } from "react";
import { useWhatsappApi } from "../../context/whatsappContex";
import "./userList.css";
import LeftSlider from "../../Inc/LeftSlider";
import Header from "../../Inc/header/Header";
import { axiosInstance } from "../../axiosInstance/axiosInstance";
import { toast } from "react-toastify";
import AdminUserList from "./AdminUserList";
import { useUser } from "../../context/UserContext";
import { Link } from "react-router-dom";

const UserList = () => {
  const { drakMode } = useUser()
  const { teamData, fetchTeamData } = useWhatsappApi();
  const [leadCount, setLeadCount] = useState([])

  const userLeadNumber = async () => {
    try {
      const response = await axiosInstance.get("/countAgent")
      setLeadCount(response.data)
    } catch (error) {
      console.log("Error fetching user wise lead list", error)
    }
  }

  useEffect(() => {
    fetchTeamData();
    userLeadNumber()
  }, []);



  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      try {
        const response = await axiosInstance.get(`/userdelete/${id}`);

        toast.success("User deleted successfully");
        fetchTeamData(); // Refresh the user list
      } catch (error) {
        console.error("There was an error deleting the user!", error);
        toast.error("An error occurred while deleting the user");
      }
    }
  };

  return (
    <>
      <section id="home-mod">
        <Header drakMode={drakMode} />
        <div className="home-mod" style={{ background: drakMode ? "#000" : "" }}>
          <LeftSlider path="user-list" />
          <div className="home-mod-right">
            <div className="scrollbar-1">
              {/* <h2>Total Lead User Wise</h2> */}
              <AdminUserList leadCount={leadCount} drakMode={drakMode} />
              <div className="user-table-container">
                <table className="user-table" style={{ background: drakMode ? "#000" : "" }}>
                  <thead style={{ background: drakMode ? "#000" : "" }}>
                    <tr style={{ background: drakMode ? "#000" : "" }}>
                      <th style={{ background: drakMode ? "#000" : "" }}>Name</th>
                      <th style={{ background: drakMode ? "#000" : "" }}>Email</th>
                      <th style={{ background: drakMode ? "#000" : "" }}>Username</th>
                      <th style={{ background: drakMode ? "#000" : "" }}>Phone</th>
                      <th style={{ background: drakMode ? "#000" : "" }}>Role</th>
                      <th style={{ background: drakMode ? "#000" : "" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teamData &&
                      teamData.map((user) => (
                        <tr key={user.id}>
                          <td style={{ color: drakMode ? "#fff" : "", background: drakMode ? "#000" : "" }}>{user?.name}</td>
                          <td style={{ color: drakMode ? "#fff" : "", background: drakMode ? "#000" : "" }}>{user?.email}</td>
                          <td style={{ color: drakMode ? "#fff" : "", background: drakMode ? "#000" : "" }}>{user?.username}</td>
                          <td style={{ color: drakMode ? "#fff" : "", background: drakMode ? "#000" : "" }}>{user?.phone}</td>
                          <td style={{ color: drakMode ? "#fff" : "", background: drakMode ? "#000" : "" }}>
                            {user?.role === "1"
                              ? "Superadmin"
                              : user?.role === "2"
                                ? "Admin"
                                : user?.role === "3"
                                  ? "Manager"
                                  : "Agent"}
                          </td>
                          <td style={{ background: drakMode ? "#000" : "" }}>
                            <div>
                              <button
                                onClick={() => handleDelete(user.id)}
                                className="delete-button"
                              >
                                Delete
                              </button>
                              <Link to={`/admin-lead-list/${user?.id}`} >
                                <button
                                  className="delete-button"
                                  style={{ background: "green", marginLeft: "10px" }}
                                >
                                  View Lead
                                </button>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {/* <div className="user-list">
              <div className="scrollbar">
                {teamData &&
                  teamData.map((user) => (
                    <div key={user.id} className="user-card">
                      <p>
                        <strong>Name:</strong> {user?.name}
                      </p>
                      <p>
                        <strong>Email:</strong> {user?.email}
                      </p>
                      <p>
                        <strong>Username:</strong> {user?.username}
                      </p>
                      <p>
                        <strong>Phone:</strong> {user?.phone}
                      </p>
                      <p>
                        <strong>Role: </strong>
                        {user?.role === "1"
                          ? "Superadmin"
                          : user?.role === "2"
                          ? "Admin"
                          : user?.role === "3"
                          ? "Manager"
                          : "agent"}
                      </p>
                      <button
                        onClick={() => handleDelete(user.id)}
                        className="delete-button"
                      >
                        Delete
                      </button>
                    </div>
                  ))}
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default UserList;
