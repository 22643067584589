import React, { useState, useEffect } from 'react'
import { MdOutlineZoomInMap, MdZoomOutMap, MdDarkMode } from "react-icons/md";
import { useUser } from '../../context/UserContext';
import { TiAdjustBrightness } from "react-icons/ti";

const ZoomInOut = () => {
    const { setDarkMode, drakMode } = useUser()
    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!drakMode)
    }

    const handleZoomIn = () => {
        const rootElement = document.getElementById("root");
        if (rootElement.requestFullscreen) {
            rootElement.requestFullscreen();
        } else if (rootElement.webkitRequestFullscreen) {
            rootElement.webkitRequestFullscreen();
        } else if (rootElement.msRequestFullscreen) {
            rootElement.msRequestFullscreen();
        }
        setIsFullscreen(true);
    };

    const handleZoomOut = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
        setIsFullscreen(false);
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
        document.addEventListener("msfullscreenchange", handleFullscreenChange);

        return () => {
            document.removeEventListener("fullscreenchange", handleFullscreenChange);
            document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
            document.removeEventListener("msfullscreenchange", handleFullscreenChange);
        };
    }, []);

    return (
        <>
            <div className="zoom-controls">
                <button onClick={isFullscreen ? handleZoomOut : handleZoomIn} style={{ background: "none" }}>
                    {isFullscreen ? (
                        <MdOutlineZoomInMap size={25} style={{ marginRight: "5px", color: "#ef5da2" }} fontSize={50} />
                    ) : (
                        <MdZoomOutMap size={25} style={{ marginRight: "5px", color: "#ef5da2" }} />
                    )}
                </button>
            </div>
            {/* <div className="zoom-controls">
                <button onClick={toggleDarkMode} style={{ background: "none" }}>
                    {drakMode ? (
                        <TiAdjustBrightness size={25} style={{ marginRight: "5px", color: "#ef5da2" }} fontSize={50} />
                    ) : (
                        <MdDarkMode size={25} style={{ marginRight: "5px", color: "#ef5da2" }} />
                    )}
                </button>
            </div> */}
        </>
    )
}

export default ZoomInOut