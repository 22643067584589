import React, { useState, useEffect } from 'react'
import Header from '../../Inc/header/Header'
import LeftSlider from '../../Inc/LeftSlider'
import { useUser } from '../../context/UserContext'
import AdminFilter from '../../admin/leadList/AdminFilter'
import { useGetLeadApi } from '../../context/getAllLeadContext'
import { axiosInstance } from '../../axiosInstance/axiosInstance'
import { useParams } from 'react-router-dom'
import LeadList from '../../admin/leadList/LeadList'
import { useWhatsappApi } from '../../context/whatsappContex'
import LeadSearch from '../../common/LeadSearch'
import AdminUserList from '../userList/AdminUserList'

const AdminWiseLeadList = () => {
    const { id } = useParams();
    const { drakMode } = useUser()
    const today = new Date().toISOString().split("T")[0];
    const { teamData, fetchTeamData, postUserIdLeadId } = useWhatsappApi();
    const {
        query,
        results,
        setPlatformSelect,
        platformSelect,
        setLeadStatus,
        leadStatus,
    } = useGetLeadApi();
    const [adminLeadList, setAdminLeadList] = useState([]);
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedTreatment, setSelectedTreatment] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [leadCount, setLeadCount] = useState([])
    const AssignName = teamData.find((e) => e?.id === parseInt(id));

    const userLeadNumber = async () => {
        try {
            const response = await axiosInstance.get("/countAgent")
            setLeadCount(response.data)
        } catch (error) {
            console.log("Error fetching user wise lead list", error)
        }
    }

    const toggleEdit = (id) => {
        setShowEdit(!showEdit);
        setSelectedPatientId(id);
    };

    const fetchData = async (platformSelect, leadStatus, selectedTreatment, startDate, endDate) => {
        setLoading(true);
        try {
            let url = `/getleadsbyagentid/${id}`;
            if (startDate && endDate) {
                url += `?start_date=${startDate}&end_date=${endDate}`;
            }

            if (platformSelect) {
                url += `?lead_type=${platformSelect}`;
            }
            if (leadStatus) {
                url += `?status=${leadStatus}`;
            }
            if (selectedTreatment) {
                url += `&speciality_id=${selectedTreatment}`;
            }
            const response = await axiosInstance.get(url);
            setAdminLeadList(response.data.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) {
            fetchData(platformSelect, leadStatus, selectedTreatment, startDate, endDate);
        }
        userLeadNumber()
        fetchTeamData();
    }, [id, platformSelect, leadStatus, selectedTreatment, startDate, endDate])

    const handleFilter = () => {
        setStartDate("");
        setEndDate("");
        setLeadStatus("");
        setPlatformSelect("");
        fetchData();
    };

    const displayData = query ? results : adminLeadList;

    return (
        <>
            {loading && (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <section id="home-mod">
                <Header drakMode={drakMode} />
                <div className="home-mod" style={{ background: drakMode ? "#000" : "" }}>
                    <LeftSlider path="user-list" />
                    <div className="home-mod-right">
                        <div className="scrollbar-1">
                            <AdminUserList leadCount={leadCount} dashbaord={false} drakMode={drakMode} />
                            <div className="leads-search-doc">
                                <h2 style={{ fontSize: "24px" }} ><span style={{ color: "#11a211" }}>{AssignName?.name}</span> Lead List</h2>
                                <div className="leads-crm-search">
                                    <LeadSearch />
                                    <AdminFilter
                                        setPlatformSelect={setPlatformSelect}
                                        platformSelect={platformSelect}
                                        setSelectedTreatment={setSelectedTreatment}
                                        selectedTreatment={selectedTreatment}
                                        setLeadStatus={setLeadStatus}
                                        leadStatus={leadStatus}
                                    />
                                </div>
                            </div>
                            <div className="lead-search">
                                <input
                                    type="date"
                                    className="lead-search-input"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    placeholder="Start Date"
                                    max={today}
                                />
                                <input
                                    type="date"
                                    className="lead-search-input"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    placeholder="End Date"
                                    max={today}
                                />
                                <button className="lead-search-button" onClick={handleFilter}>
                                    Clear
                                </button>
                                {startDate && endDate && (
                                    <div className="leads-search-doc" style={{ marginTop: "10px" }}>
                                        <h2>
                                            Total Number of Leads{" "}
                                            <span style={{ fontWeight: "bold" }}>{startDate}</span> to{" "}
                                            <span style={{ fontWeight: "bold" }}>{endDate}</span> ={" "}
                                            <span style={{ color: "#ef5da2", fontWeight: "bold" }}>
                                                {adminLeadList?.length} Leads
                                            </span>
                                        </h2>
                                    </div>
                                )}
                                <div className="leads-patient-box">
                                    <div className="leads-patient-head">
                                        <div className="leads-patient-head-box1">Patient Name</div>
                                        <div className="leads-patient-head-box2">Country</div>
                                        <div className="leads-patient-head-box3">Source</div>
                                        <div className="leads-patient-head-box4">Lead Type</div>
                                        <div className="leads-patient-head-box5">Report Status</div>
                                        <div className="leads-patient-head-box6">Assign To</div>
                                        <div className="leads-patient-head-box7">Status</div>
                                        <div className="leads-patient-head-box8">Action</div>
                                    </div>

                                    <LeadList
                                        adminLeadList={displayData}
                                        userId={id}
                                        teamData={teamData}
                                        toggleEdit={toggleEdit}
                                        showEdit={showEdit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AdminWiseLeadList