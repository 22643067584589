import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import HomePage from "./components/HomePage";
import PatientDetail from "../page/patientDetails/PatientDetail";
import Login from "../page/login/Login";
import ProtectedRoute from "../middleware/ProtectedRoute";
import CreateUser from "../page/createuser/CreateUser";
import AdminLeadList from "../admin/leadList/AdminLeadList";
import UserList from "../page/userList/UserList";
import AddPatient from "../page/add-patient/AddPatient";
import UploadPassport from "../page/upload-passport/UploadPassport";
import UploadOpinion from "../page/upload-opinion/UploadOpinion";
import UpdateProfile from "../page/update-profile/UpdateProfile";
import WhatsappTemplate from "../page/whatsappTemplate/WhatsappTemplate";
import SendVisa from "../page/sendVisa/SendVisa";
import UploadVisa from "../page/upload-visa/UploadVisa";
import TicketSend from "../page/ticket-send/TicketSend";
import SendOpinion from "../page/send-opinion/SendOpinion";
import OpinionDetail from "../page/opinion-detail/OpinionDetail";
import OpinionRequestDetail from "../page/opinion-request-detail/OpinionRequestDetail";
import HospitalVisaLetter from "../page/hospital-visa-letter/HospitalVisaLetter";
import VisaSubmisiionHospital from "../page/visa-submission-hospital/VisaSubmisiionHospital";
import TicketSubmissiionDetail from "../page/ticket-submisiion-detail/TicketSubmissiionDetail";
import UploadConveredLead from "../page/upload-convered-lead/UploadConveredLead";
import ConvertedLeadDetail from "../page/converted-lead-detail/ConvertedLeadDetail";
import Main from "../post-arrival/Main";
import PostArrivalForm from "../post-arrival/post-arrival-form/PostArrivalForm";
import DoctorConsult from "../post-arrival/doctor-consult/DoctorConsult";
import PatientAdmisiion from "../post-arrival/patient-admission/PatientAdmisiion";
import PatientDischarge from "../post-arrival/patient-discharge/PatientDischarge";
import Graph from "../graph/Graph";
import PostArrivalDetail from "../post-arrival/post-arrival-form/PostArrivalDetail";
import DoctorConsulationDetail from "../post-arrival/doctor-consult/DoctorConsulationDetail";
import PatientAdmissionDetail from "../post-arrival/patient-admission/PatientAdmissionDetail";
import PatientDischargeDetail from "../post-arrival/patient-discharge/PatientDischargeDetail";
import NotificationScheduler from "../utils/NotificationScheduler";
import AdminWiseLeadList from "../page/admin-lead-list/AdminWiseLeadList";

function App() {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <HomePage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/create-user"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <CreateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-lead-list/:id"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <AdminWiseLeadList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin-lead-list"
          element={
            <ProtectedRoute allowedRoles={["2"]} requireLogin={true}>
              <AdminLeadList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <ProtectedRoute allowedRoles={["1"]} requireLogin={true}>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/add-patient"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <AddPatient />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-passport"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <UploadPassport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/update-profile"
          element={
            <ProtectedRoute requireLogin={true}>
              <UpdateProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-opinion/:userId/:patientId"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <UploadOpinion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-visa/:userIdE/:patientIdE/:specE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <UploadVisa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-opinion/:userIdE/:patientIdE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <SendOpinion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-visa/:userIdE/:patientIdE/:specE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <SendVisa />
            </ProtectedRoute>
          }
        />
        <Route
          path="/send-ticket/:userIdE/:patientIdE/:specE"
          element={
            <ProtectedRoute allowedRoles={["1", "2"]} requireLogin={true}>
              <TicketSend />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/patient/:did"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/whatsapp-template/:did"
          element={
            <ProtectedRoute requireLogin={true}>
              <WhatsappTemplate />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/opinion-detail/:did/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <OpinionDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/opinion-request-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <OpinionRequestDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/hospital-visa-letter/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <HospitalVisaLetter />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/hospital-visa-submission/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <VisaSubmisiionHospital />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/ticket-submission-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <TicketSubmissiionDetail />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/add-converted-lead/:pid/:uid"
          element={
            <ProtectedRoute requireLogin={true}>
              <UploadConveredLead />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/convert-lead-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <ConvertedLeadDetail />
            </ProtectedRoute>
          }
        ></Route>
        {/* Post Arrival */}
        <Route
          exact
          path="/post-arrival/:pid"
          element={
            <ProtectedRoute requireLogin={true}>
              <Main />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/post-arrival-form/:pid/:uid"
          element={
            <ProtectedRoute requireLogin={true}>
              <PostArrivalForm />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/doctor-consult/:pid/:uid"
          element={
            <ProtectedRoute requireLogin={true}>
              <DoctorConsult />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path="/patient-admission/:pid/:uid"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientAdmisiion />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/patient-discharge/:pid/:uid"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientDischarge />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/graph"
          element={
            <ProtectedRoute requireLogin={true}>
              <Graph />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/post-arrival-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <PostArrivalDetail />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/doctor-consultation-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <DoctorConsulationDetail />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/patient-admisiion-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientAdmissionDetail />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/patient-discharge-detail/:pid/:uid/:idd"
          element={
            <ProtectedRoute requireLogin={true}>
              <PatientDischargeDetail />
            </ProtectedRoute>
          }
        ></Route>

        <Route
          exact
          path="/notification"
          element={
            <ProtectedRoute requireLogin={true}>
              <NotificationScheduler />
            </ProtectedRoute>
          }
        ></Route>

        <Route exact path="/login" element={<Login />}></Route>
      </Routes>
    </>
  );
}

export default App;
