import React from 'react'
import { Link } from 'react-router-dom'

const AdminUserList = ({ leadCount, dashbaord, drakMode }) => {
    return (
        <>
            <div className="graph-box-bottom">
                <ul>
                    {leadCount?.map((e) => (
                        <li key={e?.id} style={{ background: drakMode ? "#000" : "" }}>
                            <Link to={`/admin-lead-list/${e?.id}`} style={{ padding: "0px", background: "#fff" }}>
                                <div className="total-leads-box">
                                    <div className="leads-text">
                                        <h4 style={{ color: drakMode ? "#fff" : "" }}>{e?.name}</h4>
                                        <h2 style={{ gap: "0px", color: drakMode ? "#fff" : "" }}>
                                            {e?.leads_count}
                                            <span style={{ background: "#fff", color: "#632525", fontSize: "14px" }}>
                                                leads
                                            </span>
                                        </h2>
                                    </div>
                                </div>
                            </Link>
                            {/* <div className="corp-reports-day">
                                <img src="/images/2024/01/bg-1.png" />
                            </div> */}
                        </li>
                    ))}

                    {dashbaord && (
                        <li style={{ background: drakMode ? "#000" : "" }}>
                            <div className="total-leads-box">

                                <div className="leads-text">
                                    <Link to="/user-list" style={{ background: "none", padding: "0px" }}>
                                        <h2 style={{ fontSize: "18px", marginTop: "15px", fontWeight: "500" }}>View All</h2>
                                    </Link>
                                </div>

                            </div>
                            {/* <div className="corp-reports-day">
                                <img src="/images/2024/01/bg-1.png" />
                            </div> */}
                        </li>
                    )}
                </ul>
            </div>
        </>
    )
}

export default AdminUserList